import React, { useEffect, useState } from 'react';
import { generateQRCode } from '@/utils/resource';

const DownloadQRCode: React.FC<{
  className?: string;
}> = ({ className }) => {
  const [qrcode, setQRcode] = useState<string>('');

  useEffect(() => {
    const url = `${location.origin}/tmp`;
    generateQRCode(url, { margin: 2, scale: 6 }).then((qrcode) =>
      setQRcode(qrcode),
    );
  }, []);

  return <img src={qrcode} className={className} />;
};

export default DownloadQRCode;
