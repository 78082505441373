import React, { useEffect, useState } from 'react';
import styles from '@/styles/index.module.less';
import HomeBanner from '@comp/HomeBanner';
import FAQ from '@comp/FAQ';
import ProductCardList from '@comp/ProductCardList';
import SecuritySection from '@comp/Security';
import { fetchPageJson, normalizeFAQProps } from '@/utils/resource';
import { GetStaticProps } from 'next';
import { reduceToObject } from '@/utils/misc';
import { Logo } from '@const/index';
import cn from 'classnames';
import { getHotQuestions } from '@/services/helpCenter';
import { getFAQs } from '@/utils/helpCenter';

export const getStaticProps: GetStaticProps = async () => {
  const template = await fetchPageJson('home');
  const content = reduceToObject(template.content, 'render');
  if (content['FAQ']) {
    content['FAQ'] = normalizeFAQProps(content['FAQ']);
  }
  return {
    props: {
      ...template,
      content,
    },
  };
};

export default function Home({ content, setRuntimeState }: PageTmpl) {
  const [questions, setQuestions] = useState<question[]>([]);

  useEffect(() => {
    const fetchFAQs = async (limit: number) => {
      const hotList = await getHotQuestions();
      const rsp = await getFAQs(
        hotList.slice(0, limit).map((v) => v.articleId),
      );
      setQuestions(rsp);
    };
    if (content['FAQ']) {
      fetchFAQs(content['FAQ'].limit || 5);
    }
  }, [content]);

  useEffect(() => {
    if (!setRuntimeState || !questions.length) return;
    setRuntimeState({ questions });
  }, [questions, setRuntimeState]);

  const desc =
    typeof content['Desc']?.content === 'string'
      ? [content['Desc']?.content]
      : content['Desc']?.content;
  return (
    <div className={styles.container}>
      {content['Banner'] && <HomeBanner {...content['Banner']} />}
      <Logo className={styles.logo} />
      <div className="content">
        {(desc || []).map((item: string) => (
          <p
            className={cn(
              'col-md-10',
              'font-lg',
              'text-gray',
              'center',
              styles.desc,
            )}
            key={item}
          >
            {item}
          </p>
        ))}
      </div>
      {content['Products'] && (
        <ProductCardList {...content['Products']} cls={styles.productCards} />
      )}
      {content['Security'] && <SecuritySection {...content['Security']} />}
      {questions?.length > 0 && (
        <FAQ
          {...content['FAQ']}
          questions={questions}
          cls={styles.faq}
          showFindMore={true}
        />
      )}
    </div>
  );
}
