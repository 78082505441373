import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import cn from 'classnames';
// import arrowLeftDisable from '@/assets/image/arrow_left_disable.png';
import ArrowLeft from '@/assets/image/pagination_arrow_left.svg';
// import arrowRightDisable from '@/assets/image/arrow_right_disable.png';
import ArrowRight from '@/assets/image/pagination_arrow_right.svg';
import { parseString } from '@/utils/misc';
import classNames from 'classnames';

export interface PaginationProps {
  totalItemsCount: number;
  pageCount: number;
  onPageChange?: (page: number) => void;
  style?: React.CSSProperties;
  paginationDesc?: string;
}

const MaxShow = 6;
const Ellipsis = '...';

interface PageNumberProps {
  arrToShow: number[];
  arrIndex: number;
  currentPage: number;
  pageCount: number;
  isSelect: boolean;
  onClick?: (page: number) => void;
}

const defaultPaginationDesc =
  'Total: {{totalCount}} Records / {{totalPages}} pages';

const PageNumber: React.FC<PageNumberProps> = (props) => {
  const { arrToShow, arrIndex, currentPage, pageCount, isSelect } = props;
  const getPageNumToShow = (
    arrToShow: number[],
    arrIndex: number,
    currentPage: number,
    pageCount: number,
  ) => {
    if (arrIndex === 0) {
      return currentPage === 1 ? '1' : Ellipsis;
    }
    if (arrIndex === arrToShow.length - 1) {
      return currentPage === pageCount ? `${pageCount}` : Ellipsis;
    }
    return `${currentPage}`;
  };
  const toShow = useMemo(
    () => getPageNumToShow(arrToShow, arrIndex, currentPage, pageCount),
    [arrIndex, arrToShow, currentPage, pageCount],
  );
  const onClick = useCallback(() => {
    if (toShow === Ellipsis) return;
    props.onClick && props.onClick(currentPage);
  }, [currentPage, props, toShow]);
  return (
    <div
      onClick={onClick}
      className={cn({
        [styles.pageNumber]: !isSelect,
        [styles.pageNumberSelect]: isSelect,
      })}
      style={{ cursor: toShow === Ellipsis ? 'default' : 'pointer' }}
    >
      {toShow}
    </div>
  );
};

const getPageList = (pageCount: number) => {
  const pages: number[] = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }
  return pages;
};

const Pagination: React.FC<PaginationProps> = ({
  totalItemsCount,
  pageCount,
  onPageChange,
  style,
  paginationDesc = defaultPaginationDesc,
}) => {
  const pages: number[] = getPageList(pageCount);
  const initialShow = pages.slice(0, MaxShow);

  useEffect(() => {
    setCurrentShow(getPageList(pageCount).slice(0, MaxShow));
  }, [pageCount]);

  const [selectPage, setSelectPage] = useState(1);
  const [currentShow, setCurrentShow] = useState<number[]>(initialShow);

  const pageChange = (newPage: number) => {
    if (newPage < 1 || newPage > pageCount) return;
    setSelectPage(newPage);
    const currentFirst = currentShow[0];
    const currentLast = currentShow[currentShow.length - 1];
    const index = pages.findIndex((page) => page === newPage);
    if (newPage >= currentLast) {
      if (currentLast !== pageCount) {
        setCurrentShow(pages.slice(index - 4, index + 2));
      }
    } else if (newPage <= currentFirst) {
      if (currentFirst !== 1) {
        setCurrentShow(pages.slice(index - 1, index + 5));
      }
    }
    onPageChange && onPageChange(newPage);
  };

  const pageDesc = parseString(paginationDesc, {
    totalCount: totalItemsCount ? totalItemsCount?.toString() : '',
    totalPages: pageCount ? pageCount?.toString() : '',
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.paginationDesc}>{pageDesc}</div>
      <div className={styles.pagesWrapper} style={style}>
        <div
          className={classNames(styles.arrowContainer, styles.arrowLeft, {
            [styles.arrowDisabled]: selectPage === 1,
          })}
          onClick={() => pageChange(selectPage - 1)}
        >
          <ArrowLeft
            className={classNames(styles.arrowIcon, {
              [styles.arrowDisabled]: selectPage === 1,
            })}
          />
        </div>
        {currentShow.map((page, index, arr) => (
          <PageNumber
            key={index}
            arrToShow={arr}
            arrIndex={index}
            currentPage={page}
            pageCount={pageCount}
            isSelect={selectPage === page}
            onClick={pageChange}
          />
        ))}
        <div
          className={classNames(styles.arrowContainer, styles.arrowRight, {
            [styles.arrowDisabled]: selectPage === pageCount,
          })}
          onClick={() => pageChange(selectPage + 1)}
        >
          <ArrowRight className={classNames(styles.arrowIcon)} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
