import React from 'react';
import styles from './index.module.less';
import cn from 'classnames';
import { formatRouterPath } from '@/utils/misc';
import Link from 'next/link';
import arrowRight from '@/assets/image/arrow_right_light.png';

interface Props {
  path?: string;
  onClick?: () => void;
  text?: string;
  cls?: string;
  type?: 'button' | 'text';
}

const FindMore: React.FC<Props> = ({
  text = 'Find More',
  path = '',
  type = 'button',
  onClick,
  cls,
}) => {
  const _onClick = onClick || (() => {});

  const el =
    type === 'button' ? (
      <div className={cn('font-lg', styles.btn, cls)} onClick={_onClick}>
        {text}
      </div>
    ) : (
      <div className={cn('font-lg', styles.textBox, cls)} onClick={_onClick}>
        {text}
        <img src={arrowRight} />
      </div>
    );

  return onClick ? el : <Link href={formatRouterPath(path)}>{el}</Link>;
};

export default FindMore;
