import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import GooglePlayIcon from '@/assets/svg/googlePlay.svg';
import AppleStoreIcon from '@/assets/svg/appleStore.svg';
import HuaweiStoreIcon from '@/assets/svg/huaweiStore.svg';
import Link from 'next/link';
import { BrowserObject, detectBrowser } from '@/utils/browser';
import { getPageImages } from '@/utils/resource';
import DownloadQRCode from '@comp/DownloadQRCode';
import { useRouter } from 'next/router';
import SwipeBanner from '@comp/SwipeBanner';
import classNames from 'classnames';

interface Banner {
  banner: string;
  bannerMobile: string;
  title?: string;
  desc?: string | React.ReactNode;
  link?: string;
}
interface IProps {
  banners: Banner[];
  downloadUrl?: DownloadUrl;
}

const DescAndDownload: React.FC<{
  title: string;
  desc: string | React.ReactNode;
  downloadUrlForAndroid: string;
  downloadUrlForIos: string;
  downloadUrlForHuawei: string;
  className?: string;
}> = ({
  title,
  desc,
  downloadUrlForAndroid,
  downloadUrlForIos,
  downloadUrlForHuawei,
  className,
}) => (
  <div className={className}>
    <div className={styles.bannerDesc}>
      <h1>{title}</h1>
      <p>{desc}</p>
    </div>
    {downloadUrlForAndroid && downloadUrlForIos && (
      <div className={styles.downloadWrapper}>
        <div className={styles.downloadLogo}>
          <Link href={downloadUrlForAndroid}>
            <a>
              <GooglePlayIcon />
            </a>
          </Link>
          <Link href={downloadUrlForIos}>
            <a>
              <AppleStoreIcon />
            </a>
          </Link>
          <Link href={downloadUrlForHuawei}>
            <a>
              <HuaweiStoreIcon />
            </a>
          </Link>
        </div>
        <DownloadQRCode className={styles.QRCode} />
      </div>
    )}
  </div>
);

export default function HomeBanner({
  banners,
  downloadUrl: {
    downloadUrlForAndroid = '',
    downloadUrlForIos = '',
    downloadUrlForHuawei = '',
  } = {},
}: IProps) {
  const [browserInfo, setBrowserInfo] = useState<BrowserObject | undefined>();
  const [windowWidth, setWindowWidth] = useState(0);
  const router = useRouter();

  const bannersSrc = useMemo(() => {
    if (browserInfo?.isMobile) {
      return banners.map((banner) => getPageImages(banner.bannerMobile));
    }
    return banners.map((banner) => getPageImages(banner.banner));
  }, [banners, browserInfo]);

  const bannerStyle = useMemo(() => {
    if (browserInfo?.isMobile) {
      return { bannerHeight: 850, bannerWidth: windowWidth, imgHeight: 480 };
    }
    return { bannerHeight: 720, bannerWidth: windowWidth };
  }, [browserInfo, windowWidth]);

  const onBannerClick = useCallback(
    (bannerIndex: number) => {
      const banner = banners[bannerIndex] as Banner | undefined;
      banner?.link && router.push(banner.link);
    },
    [banners, router],
  );

  const renderBannerChildren = useCallback(
    (bannerIndex: number) => {
      if (bannerIndex !== 0) return null;
      const banner = banners[0];
      return (
        <DescAndDownload
          className={classNames(styles.descAndDownloadPc, 'content')}
          title={banner.title || ''}
          desc={banner.desc || ''}
          downloadUrlForAndroid={downloadUrlForAndroid}
          downloadUrlForIos={downloadUrlForIos}
          downloadUrlForHuawei={downloadUrlForHuawei}
        />
      );
    },
    [banners, downloadUrlForAndroid, downloadUrlForIos, downloadUrlForHuawei],
  );

  const renderStaticChildren = useCallback(() => {
    const banner = banners[0];
    return (
      <DescAndDownload
        className={styles.descAndDownloadMobile}
        title={banner.title || ''}
        desc={banner.desc || ''}
        downloadUrlForAndroid={downloadUrlForAndroid}
        downloadUrlForIos={downloadUrlForIos}
        downloadUrlForHuawei={downloadUrlForHuawei}
      />
    );
  }, [banners, downloadUrlForAndroid, downloadUrlForIos, downloadUrlForHuawei]);

  useEffect(() => {
    setBrowserInfo(detectBrowser());
    setWindowWidth(window.innerWidth);
    const resizeWindow = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <SwipeBanner
        banners={bannersSrc}
        bannerStyle={{
          bannerWidth: bannerStyle.bannerWidth,
          bannerHeight: bannerStyle.bannerHeight,
          imgHeight: bannerStyle.imgHeight,
          imgAlign: 'top',
        }}
        pauseWhenHover={true}
        onBannerClick={onBannerClick}
        renderBannerChildren={renderBannerChildren}
        renderStaticChildren={renderStaticChildren}
      />
    </div>
  );
}
