import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './index.module.less';
import { disableMobileScrolling, enableMobileScrolling } from '@/utils/misc';

const transIndex = (renderIndex: number, renderBannersLen: number) => {
  if (renderIndex >= renderBannersLen - 1) return 0;
  if (renderIndex <= 0) return renderBannersLen - 3;
  return renderIndex - 1;
};

const getImageAlign = (imageAlign: 'top' | 'bottom' | 'center') => {
  switch (imageAlign) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return imageAlign;
  }
};

export interface SwipeBannerProps {
  /** 需要渲染的 banner 图片列表 */
  banners: ImageDataType[];
  /** banner 样式 */
  bannerStyle: {
    /** banner 高度 */
    bannerHeight: number;
    /** banner 宽度 */
    bannerWidth: number;
    /** banner 图片高度 */
    imgHeight?: number;
    /** banner 图片宽度 */
    imgWidth?: number;
    /** banner 图片对齐方式 */
    imgAlign?: 'top' | 'bottom' | 'center';
    /** banner 图片圆角 */
    imgBorderRadius?: number;
  };
  /** Banner 轮播图是否自动播放 */
  autoPlay?: boolean;
  /** 自动播放时间间隔 */
  autoPlayInterval?: number;
  /** 在自动播放时 banner 的切换动画时间 */
  animationDuration?: number;
  /** 鼠标悬停在 banner 上时是否暂停播放 */
  pauseWhenHover?: boolean;
  /** 是否展示轮播位置圆点 */
  showDot?: boolean;
  /** 轮播位置圆点样式 */
  dotStyle?: {
    /** 圆点之间间隔 */
    gap: number;
    /** 圆点距 banner 底部距离 */
    bottom: number;
    /** 圆点未选中时的样式 */
    normalStyle: React.CSSProperties;
    /** 圆点选中时的样式 */
    activeStyle: React.CSSProperties;
  };
  /** banner 点击回调 */
  onBannerClick?: (bannerIndex: number) => void;
  /**
   * 渲染 banner 的子元素, 子元素将跟随 banner 轮播
   *
   * *子元素需要是绝对定位, 定位父元素是每个 banner*
   */
  renderBannerChildren?: (bannerIndex: number) => JSX.Element | null;
  /**
   * 渲染不跟随 banner 轮播的静态子元素
   *
   * *子元素需要是绝对定位, 定位父元素是 SwipeBanner 组件*
   */
  renderStaticChildren?: () => JSX.Element | null;
}

/**
 * Banner 轮播图
 */
const SwipeBanner: React.FC<SwipeBannerProps> = ({
  banners,
  bannerStyle: {
    bannerHeight,
    bannerWidth,
    imgHeight,
    imgWidth,
    imgAlign = 'center',
    imgBorderRadius,
  },
  autoPlay = true,
  autoPlayInterval = 5000,
  animationDuration = 500,
  pauseWhenHover,
  showDot = true,
  dotStyle = {
    gap: 8,
    bottom: 24,
    normalStyle: { width: 8, height: 8, borderRadius: 8 },
    activeStyle: { width: 24 },
  },
  onBannerClick,
  renderBannerChildren,
  renderStaticChildren,
}) => {
  const [bannerIndex, setBannerIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const autoPlayTimerRef = useRef(0);
  const renderIndexRef = useRef(1); // 前后各增加了一个 banner, 所以 render 时的 index 是从 1 开始的
  const touchStartRef = useRef({ posX: 0, posY: 0, time: 0 });
  const isInAnimationRef = useRef(false);
  const isMouseHoverRef = useRef(false);

  // 前后各加一个 banner, 实现无缝轮播
  const renderBanners = useMemo(
    () => [banners[banners.length - 1], ...banners, banners[0]],
    [banners],
  );

  // 处理轮播图片位置
  const transformBanner = useCallback(
    (
      options: {
        withAnimation?: boolean;
        duration?: number;
        translateX?: number;
      } = {},
    ) => {
      const {
        withAnimation = true,
        duration = animationDuration,
        translateX,
      } = options;
      const container = containerRef.current;
      if (!container) return;
      if (withAnimation) {
        container.style.transition = `transform ${duration / 1000}s ease`;
        isInAnimationRef.current = true;
      } else {
        container.style.transition = 'none';
      }
      const transX = translateX ?? -(bannerWidth * renderIndexRef.current);
      container.style.transform = `translateX(${transX}px)`;
    },
    [animationDuration, bannerWidth],
  );

  // 处理轮播图片切换动画结束
  const onAnimationEnd = useCallback(() => {
    const renderIndex = renderIndexRef.current;
    if (renderIndex === renderBanners.length - 1) {
      renderIndexRef.current = 1; // 是最后一个的话, 需要在动画结束后替换
      transformBanner({ withAnimation: false }); // 替换时要去掉动画
    }
    if (renderIndex === 0) {
      renderIndexRef.current = renderBanners.length - 2; // 是第一个的话, 需要在动画结束后替换
      transformBanner({ withAnimation: false }); // 替换时要去掉动画
    }
    isInAnimationRef.current = false;
  }, [renderBanners.length, transformBanner]);

  // 自动播放
  const playAutomatically = useCallback(() => {
    if (!autoPlay || banners.length <= 1) return;
    autoPlayTimerRef.current = window.setInterval(() => {
      const isMouseHover = isMouseHoverRef.current;
      if ((pauseWhenHover && isMouseHover) || document.hidden) return; // 鼠标悬停或者页面隐藏时不自动播放
      const newRenderIndex = renderIndexRef.current + 1;
      const bannerIndex = transIndex(newRenderIndex, renderBanners.length);
      setBannerIndex(bannerIndex);
      renderIndexRef.current = newRenderIndex;
      transformBanner();
    }, autoPlayInterval);
  }, [
    autoPlay,
    autoPlayInterval,
    banners.length,
    pauseWhenHover,
    renderBanners.length,
    transformBanner,
  ]);

  // 清除自动播放
  const clearAutoPlay = useCallback(() => {
    window.clearInterval(autoPlayTimerRef.current);
  }, []);

  // 播放指定 renderIndex 处的 banner
  const playIndex = useCallback(
    (renderIndex: number, duration?: number) => {
      if (banners.length <= 1) return;
      clearAutoPlay(); // 如果是手动播放 banner 则清除自动播放定时器
      const bannerIndex = transIndex(renderIndex, renderBanners.length);
      setBannerIndex(bannerIndex);
      renderIndexRef.current = renderIndex;
      transformBanner({ duration });
      playAutomatically(); // 重新自动播放
    },
    [playAutomatically, banners, clearAutoPlay, renderBanners, transformBanner],
  );

  const touchSwipe = useCallback(
    (e: React.TouchEvent) => {
      const touchType = e.nativeEvent.type;
      const touchStart = touchStartRef.current;
      const renderIndex = renderIndexRef.current;
      const isInAnimation = isInAnimationRef.current;
      if (
        banners.length <= 1 ||
        renderIndex === renderBanners.length - 1 ||
        renderIndex === 0
      )
        return;
      if (touchType === 'touchstart') {
        disableMobileScrolling();
        touchStart.posX = e.nativeEvent.touches[0].pageX;
        touchStart.posY = e.nativeEvent.touches[0].pageY;
        touchStart.time = Date.now();
      } else if (touchType === 'touchmove') {
        if (isInAnimation) return;
        const diffX = e.nativeEvent.changedTouches[0].pageX - touchStart.posX;
        const currentTransX = -renderIndex * bannerWidth;
        transformBanner({
          withAnimation: false,
          translateX: currentTransX + diffX,
        });
      } else if (touchType === 'touchend') {
        const diffX = e.nativeEvent.changedTouches[0].pageX - touchStart.posX;
        const immediate = Date.now() - touchStartRef.current.time <= 300;
        let newIndex = renderIndex;
        if (diffX > bannerWidth / 2 || (immediate && diffX > 0)) {
          newIndex = renderIndex - 1;
        } else if (diffX < -bannerWidth / 2 || (immediate && diffX < 0)) {
          newIndex = renderIndex + 1;
        }
        playIndex(newIndex, 200);
        enableMobileScrolling();
      }
    },
    [
      bannerWidth,
      banners.length,
      playIndex,
      renderBanners.length,
      transformBanner,
    ],
  );

  const bannerClick = useCallback(
    (renderIndex: number) => {
      const bannerIndex = transIndex(renderIndex, renderBanners.length);
      onBannerClick && onBannerClick(bannerIndex);
    },
    [onBannerClick, renderBanners.length],
  );

  const mouseEnter = useCallback(() => {
    isMouseHoverRef.current = true;
  }, []);

  const mouseLeave = useCallback(() => {
    isMouseHoverRef.current = false;
  }, []);

  const renderChildren = useCallback(
    (renderIndex: number) => {
      const bannerIndex = transIndex(renderIndex, renderBanners.length);
      if (renderBannerChildren) {
        return renderBannerChildren(bannerIndex);
      }
      return null;
    },
    [renderBannerChildren, renderBanners.length],
  );

  const renderStaicChildren = useCallback(() => {
    if (renderStaticChildren) {
      return renderStaticChildren();
    }
    return null;
  }, [renderStaticChildren]);

  useEffect(() => {
    transformBanner({ withAnimation: false });
  }, [transformBanner]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    playAutomatically();
    container.addEventListener('transitionend', onAnimationEnd);
    return () => {
      clearAutoPlay();
      container.removeEventListener('transitionend', onAnimationEnd);
    };
  }, [playAutomatically, banners, clearAutoPlay, onAnimationEnd]);

  return (
    <div
      className={styles.wrapper}
      style={{ height: bannerHeight }}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <div
        ref={containerRef}
        style={{ width: bannerWidth * renderBanners.length }}
        onTouchStart={touchSwipe}
        onTouchMove={touchSwipe}
        onTouchEnd={touchSwipe}
      >
        {renderBanners.map((itemSrc, index) => (
          <div
            key={index}
            className={styles.bannerItem}
            style={{
              width: bannerWidth,
              height: bannerHeight,
              alignItems: getImageAlign(imgAlign),
            }}
          >
            <div
              className={styles.banner}
              style={{
                height: imgHeight || bannerHeight,
                width: imgWidth || '100%',
                borderRadius: imgBorderRadius,
                background: `center / cover no-repeat url(${itemSrc.src})`,
              }}
              onClick={() => bannerClick(index)}
            />
            {renderChildren(index)}
          </div>
        ))}
      </div>
      {renderStaicChildren()}
      {banners.length > 1 && showDot ? (
        <div className={styles.dotWrapper} style={{ bottom: dotStyle.bottom }}>
          {banners.map((_banner, index) => (
            <div
              key={index}
              style={
                index === bannerIndex
                  ? {
                      ...dotStyle.normalStyle,
                      ...dotStyle.activeStyle,
                      marginRight: dotStyle.gap,
                      transition: `all ${animationDuration / 1000}s ease`,
                    }
                  : {
                      ...dotStyle.normalStyle,
                      marginRight: dotStyle.gap,
                      transition: `all ${animationDuration / 1000}s ease`,
                    }
              }
              onClick={() => playIndex(index + 1)}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SwipeBanner;
