import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import styles from './index.module.less';
import cn from 'classnames';
import MinusIconSvg from '@/assets/svg/minus_icon.svg';
import AddIconSvg from '@/assets/svg/add_icon.svg';
import arrowRightGray from '@/assets/image/arrow_right_gray.png';
import FindMore from '@comp/FindMore';
import { handleClickMDLink } from '@/utils/misc';
import Pagination from '@comp/Pagination';
import { detectBrowser } from '@/utils/browser';
import {
  CategoryMapType,
  flatCategoryTreeToObj,
  getArticlePath,
} from '@/utils/helpCenter';
import { ROOT_PATH } from '@/constant/helpCenter';
import { getCategories, getDetail } from '@/services/helpCenter';

interface Props {
  title?: string;
  questions: question[];
  cls?: string;
  itemCls?: string;
  showFindMore?: boolean;
  seeMoreBtnType?: 'button' | 'text';
  pagination?: {
    total: number;
    pageSize: number;
  } | null;
  onPageChange?: (pageNum: number) => void;
  linkText?: string;
  canExpand?: boolean;
  /** Find More 按钮点击回调函数, 提供了点击回调将覆盖 Find More 按钮的默认行为 */
  onBtnClick?: () => void;
  paginationDesc?: string;
}

type EssentialQuestionData = Pick<
  question,
  'articleId' | 'title' | 'categoryId'
>;

type ItemProps = EssentialQuestionData & {
  content?: string;
  canExpand?: boolean;
  cls?: string;
  expand?: boolean;
};

export const QuestionItem: React.FC<
  ItemProps & { categoryMap?: CategoryMapType }
> = ({
  categoryId,
  articleId,
  title,
  content,
  expand,
  canExpand,
  cls,
  // categoryMap,
}) => {
  const router = useRouter();
  const [isShow, setIsShow] = useState(Boolean(expand));
  const [browserInfo, setBrowserInfo] = useState<any>(null);

  const [questionData, setQuestionData] = useState<question>({
    articleId,
    content: content || '',
    title,
    categoryId,
  });

  useEffect(() => {
    setBrowserInfo(detectBrowser());
  }, []);

  const renderIcon = useMemo(() => {
    if (canExpand) {
      if (isShow) {
        return <MinusIconSvg className={styles.icon} />;
      } else {
        return <AddIconSvg className={styles.icon} />;
      }
    } else if (!browserInfo?.isMobile) {
      return <img className={styles.arrowRight} src={arrowRightGray} />;
    }
  }, [isShow, canExpand, browserInfo?.isMobile]);

  useEffect(() => {
    if (!articleId) return;

    getDetail(articleId).then((data) => {
      setQuestionData(data);
    });
  }, [articleId]);

  useEffect(() => {}, []);

  useEffect(() => {
    setIsShow(Boolean(expand));
  }, [expand]);

  return (
    <div className={styles.questionItemWrapper}>
      <div
        className={cn(
          'row',
          'row-space-between',
          'row-middle',
          styles.questionHeader,
          cls,
        )}
        onClick={() => {
          if (canExpand) {
            setIsShow(!isShow);
          } else {
            router.push(getArticlePath(articleId?.toString()));
          }
        }}
      >
        <div
          className={cn('font-lg', styles.questionTitle)}
          dangerouslySetInnerHTML={{ __html: questionData?.title }}
        />
        {renderIcon}
      </div>
      {isShow && (
        <div
          className={cn('font-lg', 'text-gray', styles.questionCont)}
          dangerouslySetInnerHTML={{ __html: questionData?.content || '' }}
        />
      )}
    </div>
  );
};

const FAQ: React.FC<Props> = ({
  title,
  questions,
  linkText,
  seeMoreBtnType,
  onBtnClick,
  cls,
  itemCls,
  showFindMore,
  pagination,
  onPageChange,
  canExpand = true,
  paginationDesc,
}) => {
  const [categoryMap, setCategoryMap] = useState<CategoryMapType>();

  useEffect(() => {
    getCategories().then((data) => {
      setCategoryMap(flatCategoryTreeToObj(data));
    });
  }, []);
  if (questions.length <= 0) return null;

  return (
    <div
      className={cn('content', styles.container, cls)}
      onClick={handleClickMDLink}
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={cn('col-md-10', 'center', styles.content)}>
        {questions.map((item, index) => (
          <QuestionItem
            key={`${item.title}-${index}`}
            canExpand={canExpand}
            cls={itemCls}
            categoryMap={categoryMap}
            {...item}
          />
        ))}
        {pagination && (
          <div className={styles.pagination}>
            <Pagination
              paginationDesc={paginationDesc}
              totalItemsCount={pagination.total}
              pageCount={
                pagination
                  ? Math.ceil(pagination.total / pagination.pageSize)
                  : 0
              }
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
      {showFindMore && (
        <FindMore
          onClick={onBtnClick}
          text={linkText}
          path={ROOT_PATH}
          type={seeMoreBtnType}
          cls={cn('col-md-10', styles.findMore)}
        />
      )}
    </div>
  );
};

export default FAQ;
