import React from 'react';
import styles from './index.module.less';
import cn from 'classnames';
import { getPageImages } from '@/utils/resource';

interface SecurityItem {
  imageUrl: string;
  title: string;
  desc: string;
}

interface Props {
  title: string;
  items: SecurityItem[];
  cls?: string;
}

const SecurityCard: React.FC<SecurityItem> = ({ imageUrl, title, desc }) => {
  return (
    <div className={cn('col-12', 'col-md-6', styles.cardWrapper)}>
      <img className={styles.image} {...getPageImages(imageUrl)} />
      <h3 className={cn('title-sm', styles.title)}>{title}</h3>
      <div className={cn('font-lg', 'text-gray', styles.desc)}>{desc}</div>
    </div>
  );
};

const SecuritySection: React.FC<Props> = ({ title, items, cls }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.header}>{title}</h2>
      <div className={cn('content', 'row', 'row-space-between', cls)}>
        {items.map((item) => (
          <SecurityCard key={item.title} {...item} />
        ))}
      </div>
    </div>
  );
};

export default SecuritySection;
